import React from "react";

function CTA() {
  return (
    <section class="py-8 py-md-11 bg-dark">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 text-center">
            <h1 class="display-5 text-white">Like what you see?</h1>

            <p class="fs-lg text-white mb-6 mb-md-8">
              Chat with us today to discuss how the Mi Connect widget could
              revolutionise your inbound lead generation.
            </p>

            <a
              href="javascript:Mi_connect_API.maximise()"
              class="btn btn-success lift"
            >
              Get Started <i class="fe fe-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
export default CTA;
