import React from "react";

function Brands() {
  return (
    <section class="py-6 py-md-8 border-bottom">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
            <div class="img-fluid text-gray-600 mb-2 mb-md-0 d-flex justify-content-center">
              <img
                style={{ maxHeight: "100px" }}
                src="/miconnect/assets/dnrg.png"
              />{" "}
            </div>
          </div>
          <div class="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
            <div class="img-fluid text-gray-600 mb-2 mb-md-0 d-flex justify-content-center">
              <img
                style={{ maxHeight: "100px" }}
                src="/miconnect/assets/instantbreaks.png"
              />{" "}
            </div>
          </div>
          <div class="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
            <div class="img-fluid text-gray-600 mb-2 mb-md-0 d-flex justify-content-center">
              <img
                style={{ maxHeight: "100px" }}
                src="/miconnect/assets/talkin.png"
              />{" "}
            </div>
          </div>
          <div class="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
            <div class="img-fluid text-gray-600 mb-2 mb-md-0 d-flex justify-content-center">
              <img
                style={{ maxHeight: "100px" }}
                src="/miconnect/assets/talkone.png"
              />{" "}
            </div>
          </div>

          <div class="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
            <div class="img-fluid text-gray-600 mb-2 mb-md-0 d-flex justify-content-center">
              <img
                style={{ maxHeight: "100px" }}
                src="/miconnect/assets/mi-telecom.png"
              />{" "}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Brands;
