import React from "react";

function FAQ() {
  return (
    <>
      <div class="position-relative mt-n30">
        <div
          class="shape shape-bottom shape-fluid-x text-dark"
          style={{ top: "0px" }}
        >
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z"
              fill="currentColor"
            />
          </svg>{" "}
        </div>
      </div>

      <section class="mt-n29 pt-30 bg-dark">
        <div class="container pt-8 pt-md-11">
          <div class="row">
            <div class="col-12"></div>
            <div class="col-12 col-md-6">
              <div class="d-flex">
                <div class="badge badge-lg badge-rounded-circle bg-success">
                  <span>?</span>
                </div>

                <div class="ms-5">
                  <h4 class="text-white fw-bolder">
                    Can I customise the widget to match my company?
                  </h4>

                  <p class="text-white mb-6 mb-md-8">
                    Whether you want the colour of the widget to stand out, or
                    the welcome text to sell a new product, you have entire
                    flexibility over your widget. Don’t want a Messenger option?
                    Just hide it. Make changes in real time to every aspect,
                    without having to change any code.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="d-flex">
                <div class="badge badge-lg badge-rounded-circle bg-success">
                  <span>?</span>
                </div>

                <div class="ms-5">
                  <h4 class="text-white fw-bolder">Do I need a developer?</h4>

                  <p class="text-white mb-6 mb-md-8">
                    If you have Google Tag Manager (GTM) installed on your site
                    then you can install the Mi connect widget within seconds.
                    Simply drop in our few lines of code and you’re away. If you
                    don’t have GTM installed, then just drop it inline. We can
                    help you.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12"></div>
            <div class="col-12 col-md-6">
              <div class="d-flex">
                <div class="badge badge-lg badge-rounded-circle bg-success">
                  <span>?</span>
                </div>

                <div class="ms-5">
                  <h4 class="text-white fw-bolder">
                    What happens when I hit my callback limit?
                  </h4>

                  <p class="text-white mb-6 mb-md-8">
                    We don’t want you to miss calls from potential customers, so
                    if you’re getting close to your callback limit, we’ll let
                    you know. All of our bundles have a 10% out of bundle
                    tolerance so you can keep receiving calls. If you don’t want
                    to top up, the callback widget option will be hidden.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="d-flex">
                <div class="badge badge-lg badge-rounded-circle bg-success">
                  <span>?</span>
                </div>

                <div class="ms-5">
                  <h4 class="text-white fw-bolder">
                    Can I connect the stats to Adwords and Facebook?
                  </h4>

                  <p class="text-white mb-6 mb-md-8">
                    Sure! Everytime there is an action, we push an Event up into
                    the GTM data layer. Capture it and start tracking
                    conversions directly with Google and Facebook.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="d-flex">
                <div class="badge badge-lg badge-rounded-circle bg-success">
                  <span>?</span>
                </div>

                <div class="ms-5">
                  <h4 class="text-white fw-bolder">
                    What happens if I miss a callback?
                  </h4>

                  <p class="text-white mb-6 mb-md-8">
                    If your sales team is busy, we won’t try and call the
                    customer, instead, we’ll text the customer and let them know
                    that you’ll call them back as soon as possible. You will
                    also receive an email from us to let you know that you’ve
                    had a missed call.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default FAQ;
