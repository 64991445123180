import React from "react";

function Features() {
  return (
    <section class="py-8 py-md-11 border-bottom">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-4" data-aos="fade-up">
            <div class="icon text-primary mb-3" style={{ height: "70px" }}>
              <img
                style={{ height: "60px" }}
                src="/miconnect/assets/Increase_sales.png"
              />
            </div>

            <h3>Increase Sales Leads</h3>

            <p class="text-muted mb-6 mb-md-0">
              Maximise marketing spend by capturing and converting leads
              directly within the widget.
            </p>
          </div>
          <div class="col-12 col-md-4" data-aos="fade-up" data-aos-delay="50">
            <div class="icon text-primary mb-3" style={{ height: "70px" }}>
              <img
                style={{ height: "60px" }}
                src="/miconnect/assets/Multi-channel.png"
              />
            </div>

            <h3>Multi-channel Solution</h3>

            <p class="text-muted mb-6 mb-md-0">
              Customise your widget to include; Instant callback, Messenger,
              WhatsApp, Live Chat, Lead Form and direct calls.
            </p>
          </div>
          <div class="col-12 col-md-4" data-aos="fade-up" data-aos-delay="100">
            <div class="icon text-primary mb-3" style={{ height: "65px" }}>
              <img
                style={{ height: "50px" }}
                src="/miconnect/assets/Callback.png"
              />
            </div>

            <h3>Immediate Call Back</h3>

            <p class="text-muted mb-0">
              Gets sales teams calling hot leads within as little as 20 seconds
              via our smart, automated process.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Features;
