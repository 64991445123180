import React from "react";

function Welcome() {
  return (
    <section class="pt-4 pt-md-5">
      <div class="container-fluid" style={{ maxWidth: "1400px" }}>
        <div class="row align-items-center">
          <div class="col-12 col-md-6 col-lg-7 order-md-2">
            <img
              src="assets/Mi-connect-header-image.png"
              class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 header-img"
              alt="..."
              data-aos="fade-up"
              data-aos-delay="100"
            />
          </div>
          <div class="col-12 col-md-6 col-lg-5 order-md-1" data-aos="fade-up">
            <h1 class="display-1 text-center text-md-start">
              Get ready to generate more leads!
            </h1>

            <p class="lead text-center text-md-start text-muted mb-6 mb-lg-8">
              Launch your own customised Connect widget to your website within
              minutes.
            </p>

            <div class="text-center text-md-start">
              <a
                class="btn btn-primary shadow lift me-1"
                href="javascript:Mi_connect_API.maximise()"
              >
                Chat to us{" "}
                <i class="fe fe-arrow-right d-none d-md-inline ms-3"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Welcome;
