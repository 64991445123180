import React from "react";

function Footer() {
  return (
    <>
      <div class="position-relative">
        <div class="shape shape-bottom shape-fluid-x text-gray-200">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z"
              fill="currentColor"
            />
          </svg>{" "}
        </div>
      </div>

      <footer class="py-8 py-md-11 bg-gray-200">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-4 col-lg-8">
              <img
                src="/miconnect/assets/img/miconnectlogo.png"
                class="navbar-brand-img"
                alt="..."
              />

              <p class="text-black-700  pt-2 fw-bolder m-0">
                A Mi-telecom brand
              </p>
              <p class="text-black-700  fw-bolder">
                Suite 2, 4a Rodley Road Bromley London BR1 3JL
              </p>
              <p class="text-black-700  fw-bolder">0330 880 3040</p>
              <p class="text-black-700  fw-bolder">hello@mi-connect.co.uk</p>
            </div>

            <div class="col-12 col-md-8 col-lg-4 fw-bold">
              <ul class="list-unstyled text-black mb-6 mb-md-8 mb-lg-0">
                <li class="mb-3">
                  <a href="#!" class="text-reset">
                    Terms & Conditions
                  </a>
                </li>
                <li class="mb-3">
                  <a href="#!" class="text-reset">
                    Privacy Policy
                  </a>
                </li>
                <li class="mb-3">
                  <a href="#!" class="text-reset">
                    Cookie Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
