import React from "react";
import Welcome from "../Components/Welcome";
import Features from "../Components/Features";
import Brands from "../Components/Brands";
import About from "../Components/About";
import Pricing from "../Components/Pricing";
import FAQ from "../Components/FAQ";
import CTA from "../Components/CTA";
import Footer from "../Components/Footer";
import Testamonials from "../Components/Testamonials";

function Home() {
  return (
    <>
      <Welcome />
      <Features />
      <Brands />
      <About />
      <Testamonials />
      <Pricing />
      <FAQ />
      <CTA />
      <Footer />
    </>
  );
}
export default Home;
