import React from "react";

function Pricing() {
  return (
    <section class="pt-9 pt-md-12 bg-gray-200">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 text-center pb-5">
            <h1>Fair, simple pricing for all.</h1>

            <p class="lead text-gray-700">
              Pricing is on a per-month, subscription basis;
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4">
            <div
              class="card rounded-lg shadow-lg mb-6 mb-md-0"
              style={{ zIndex: "1" }}
              data-aos="fade-up"
            >
              <div class="card-body py-6 py-md-8">
                <div class="row justify-content-center">
                  <div class="col-12 col-xl-9">
                    <div class="text-center mb-5">
                      <span class="badge rounded-pill bg-primary-soft">
                        <span class="h6 fw-bold text-uppercase">Lite</span>
                      </span>
                    </div>

                    <div class="d-flex justify-content-center">
                      <span class="h2 mb-0 mt-2">£</span>
                      <span
                        class="price display-2 mb-0"
                        data-annual="29"
                        data-monthly="49"
                      >
                        50
                      </span>
                      <span class="h2 align-self-end mb-1">/mo</span>
                    </div>

                    <p class="text-center text-muted mb-6 mb-md-8"></p>

                    <div class="d-flex">
                      <div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i class="fe fe-check"></i>
                      </div>

                      <p>50 Calls via the call back widget</p>
                    </div>
                    <div class="d-flex">
                      <div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i class="fe fe-check"></i>
                      </div>

                      <p>Unlimited clicks on all widgets</p>
                    </div>
                    <div class="d-flex">
                      <div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i class="fe fe-check"></i>
                      </div>

                      <p>Unlimited impressions</p>
                    </div>
                    <div class="d-flex">
                      <div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i class="fe fe-check"></i>
                      </div>

                      <p>Customise the Mi connect widget</p>
                    </div>
                    <div class="d-flex">
                      <div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i class="fe fe-check"></i>
                      </div>

                      <p class="mb-0">
                        Access to all options; Call back, Messenger, WhatsApp,
                        Email, Lead Form, Telephone, Live Chat
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-4">
            <div
              class="card rounded-lg shadow-lg mb-6 mb-md-0"
              style={{ zIndex: "1" }}
              data-aos="fade-up"
            >
              <div class="card-body py-6 py-md-8">
                <div class="row justify-content-center">
                  <div class="col-12 col-xl-9">
                    <div class="text-center mb-5">
                      <span class="badge rounded-pill bg-primary-soft">
                        <span class="h6 fw-bold text-uppercase">Standard</span>
                      </span>
                    </div>

                    <div class="d-flex justify-content-center">
                      <span class="h2 mb-0 mt-2">£</span>
                      <span
                        class="price display-2 mb-0"
                        data-annual="29"
                        data-monthly="49"
                      >
                        200
                      </span>
                      <span class="h2 align-self-end mb-1">/mo</span>
                    </div>

                    <p class="text-center text-muted mb-6 mb-md-8"></p>

                    <div class="d-flex">
                      <div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i class="fe fe-check"></i>
                      </div>

                      <p>250 Calls via the call back widget</p>
                    </div>
                    <div class="d-flex">
                      <div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i class="fe fe-check"></i>
                      </div>

                      <p>Everything included in Lite.</p>
                    </div>
                    <div class="d-flex">
                      <div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i class="fe fe-check"></i>
                      </div>

                      <p>Use the Mi Connect widget across multiple domains</p>
                    </div>
                    <div class="d-flex">
                      <div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i class="fe fe-check"></i>
                      </div>

                      <p>Daily analytic emails</p>
                    </div>
                    <div class="d-flex">
                      <div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i class="fe fe-check"></i>
                      </div>

                      <p>Installation support</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-4">
            <div
              class="card rounded-lg shadow-lg mb-6 mb-md-0"
              style={{ zIndex: "1" }}
              data-aos="fade-up"
            >
              <div class="card-body py-6 py-md-8">
                <div class="row justify-content-center">
                  <div class="col-12 col-xl-9">
                    <div class="text-center mb-5">
                      <span class="badge rounded-pill bg-primary-soft">
                        <span class="h6 fw-bold text-uppercase">Premium</span>
                      </span>
                    </div>

                    <div class="d-flex justify-content-center">
                      <span class="h2 mb-0 mt-2">£</span>
                      <span
                        class="price display-2 mb-0"
                        data-annual="29"
                        data-monthly="49"
                      >
                        500
                      </span>
                      <span class="h2 align-self-end mb-1">/mo</span>
                    </div>

                    <p class="text-center text-muted mb-6 mb-md-8"></p>

                    <div class="d-flex">
                      <div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i class="fe fe-check"></i>
                      </div>

                      <p>1000 Calls via the call back widget</p>
                    </div>
                    <div class="d-flex">
                      <div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i class="fe fe-check"></i>
                      </div>

                      <p>Everything included in Lite and Standard</p>
                    </div>
                    <div class="d-flex">
                      <div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i class="fe fe-check"></i>
                      </div>

                      <p>
                        Access to the Mi Connect API to connect event data into
                        your own platform
                      </p>
                    </div>
                    <div class="d-flex">
                      <div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i class="fe fe-check"></i>
                      </div>

                      <p>Free website integration</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Pricing;
