import AOS from "aos";
import Home from "./Pages/Home";
import Nav from "./Components/Navigation";
import "./App.css";

function App() {
  AOS.init();

  return (
    <div className="container-fluid">
      <div className="row">
        <Nav />
      </div>
      <div className="row">
        <Home />
      </div>
    </div>
  );
}

export default App;
