import React from "react";

function Navigation() {
  return (
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <a class="navbar-brand" href="/index.html">
          <img
            src="/miconnect/assets/img/miconnectlogo.png"
            class="navbar-brand-img"
            alt="..."
          />
        </a>

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarCollapse">
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fe fe-x"></i>
          </button>

          <a
            class="navbar-btn btn btn-sm btn-primary lift ms-auto"
            href="javascript:Mi_connect_API.maximise()"
          >
            Let's Chat
          </a>
        </div>
      </div>
    </nav>
  );
}
export default Navigation;
