import React from "react";

function Testamonials() {
  return (
    <section class="pt-10 pt-md-12">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 text-center">
            <h2>Data, data, data</h2>

            <p class="fs-lg text-muted mb-7 mb-md-9">
              It’s important to every successful business and we recognise that.
              We can tell you everything from; number of unique visits to the
              widget, interactions, events and details about the callback.
              <br />
              <br /> Get detailed insights into missed calls, answered calls,
              the page the customer is browsing when they requested the call and
              so much more.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testamonials;
